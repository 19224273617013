import { UserOutlined } from '@ant-design/icons'
import { Dropdown, Menu as ATNDMenu } from 'antd'
import * as Styled from 'components/Menu/styled'
import { MenuItemProps } from 'pages/Matches/_components/MatchesMenu'
import React from 'react'

interface UserMenuProps {
  menuItems: MenuItemProps[]
}

const UserMenu: React.FC<UserMenuProps> = ({ menuItems }) => {
  const overlay = (
    <ATNDMenu>
      {menuItems.map((menuItem, i) => (
        <ATNDMenu.Item key={i} onClick={menuItem.onSelect}>
          <span>{menuItem.title}</span>
        </ATNDMenu.Item>
      ))}
    </ATNDMenu>
  )

  return (
    <Dropdown overlay={overlay}>
      <Styled.UserButton className="ant-dropdown-link" onClick={e => e?.preventDefault()}>
        <UserOutlined />
      </Styled.UserButton>
    </Dropdown>
  )
}

export default UserMenu
