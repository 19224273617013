import styled, { keyframes } from 'styled-components'

export enum LoaderSize {
  SMALL = 'SMALL',
  DEFAULT = 'DEFAULT',
}

const sizeToPixels = {
  [LoaderSize.SMALL]: {
    size: 40,
  },
  [LoaderSize.DEFAULT]: {
    size: 15,
  },
}

const LoadingAnimation = keyframes`
  0% { transform: translate3d(0px, 0px, 0px);}
  40% { transform: translate3d(0px, -8px, 0px);}
  80% { transform: translate3d(0px, 8px, 0px);}
  100% { transform:   translate3d(0px, 0px, 0px);}
`

export const StyledLoader = styled.div<{ size: LoaderSize }>`
  width: ${({ size }) => sizeToPixels[size].size}px;
  height: ${({ size }) => sizeToPixels[size].size}px;
  animation: ${LoadingAnimation} 0.9s linear infinite;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
