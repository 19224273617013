interface Theme {
  colors: {
    darkWhite: string
    surface: string
    background: string
    grey: string
    green: string
    blue: string
    red: string
    alphaGrey: string
    darkGrey: string
    almostWhite: string
    danger: string
  }
}

export const theme: Theme = {
  colors: {
    darkWhite: '#dddddb',
    surface: '#252525',
    background: 'white',
    grey: '#606060',
    green: '#00FF9D',
    blue: '#034694',
    red: '#C8102E',
    alphaGrey: 'rgba(255, 255, 255, 0.7)',
    darkGrey: '#333333',
    almostWhite: '#e2e2e2',
    danger: '#FF4D4F',
  },
}
