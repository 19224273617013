import Settings from 'pages/Settings'
import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Routes from './routes'

const Matches = React.lazy(() => import('pages/Matches'))
const Match = React.lazy(() => import('pages/Match'))
const Moment = React.lazy(() => import('pages/Moment'))
const SignIn = React.lazy(() => import('pages/SignIn'))
const SimpleStory = React.lazy(() => import('pages/SimpleStory'))
const SimplePage = React.lazy(() => import('pages/SimplePage'))

const App = () => {
  return (
    <>
      <Switch>
        <Route path={Routes.MATCHES} component={Matches} />
        <Route path={Routes.MATCH} component={MatchStory} />
        <Route path={Routes.SIMPLE_STORY} component={SimpleStorySwitch} />
        <Route path={Routes.SETTINGS} component={Settings} />
        <Redirect to={Routes.MATCHES} />
      </Switch>
    </>
  )
}

const MatchStory = () => (
  <Switch>
    <Route exact path={Routes.MATCH} component={Match} />
    <Route path={Routes.MATCH_STORY} component={MatchStoryMoment} />
    <Redirect to={Routes.MATCHES} />
  </Switch>
)

const SimpleStorySwitch = () => (
  <Switch>
    <Route exact path={Routes.SIMPLE_STORY} component={SimpleStory} />
    <Route exact path={Routes.SIMPLE_STORY_PAGE} component={SimplePage} />
    <Redirect to={Routes.MATCHES} />
  </Switch>
)

const MatchStoryMoment = () => (
  <Switch>
    <Route exact path={Routes.MATCH_STORY_MOMENT_EDIT} component={Moment} />
    <Route exact path={Routes.MATCH_STORY_MOMENT_ADD} component={Moment} />
    <Redirect to={Routes.MATCHES} />
  </Switch>
)

export const Protected = () => <Route path={Routes.ROOT} component={App} />

export const Public = () => {
  return (
    <Switch>
      <Route path={Routes.SIGN_IN} component={SignIn} />
      <Redirect to={Routes.SIGN_IN} />
    </Switch>
  )
}
