import '@reach/menu-button/styles.css'

import Amplify from 'aws-amplify'
import Config from 'config'
import GlobalStyle from 'constants/globalStyle'
import { theme } from 'constants/theme'
import AuthProvider from 'hooks/providers/Auth'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ConfigProvider } from 'antd'
import locale from 'antd/es/locale/en_GB'
import moment from 'moment'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const {
  cognito: { USER_POOL_ID, USER_POOL_CLIENT_ID, AWS_REGION, IDENTITY_POOL_ID, MATCH_IMAGES_BUCKET_NAME },
  sentry: { SENTRY_DSN, SENTRY_ENVIRONMENT, SENTRY_RELEASE },
} = Config

Amplify.configure({
  Auth: {
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_CLIENT_ID,
    region: AWS_REGION,
    identityPoolId: IDENTITY_POOL_ID,
  },
  Storage: {
    AWSS3: {
      bucket: MATCH_IMAGES_BUCKET_NAME,
      region: AWS_REGION,
      identityPoolId: IDENTITY_POOL_ID,
    },
  },
})

moment.locale('en_GB', {
  week: {
    dow: 1,
  },
})

Sentry.init({
  dsn: SENTRY_DSN,
  release: SENTRY_RELEASE,
  environment: SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
  debug: true,
  enabled: SENTRY_RELEASE !== 'development',
})

//eslint-disable-next-line
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <BrowserRouter>
      <AuthProvider>
        <ConfigProvider locale={locale}>
          <App />
        </ConfigProvider>
      </AuthProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
