import React, { useEffect, useState } from 'react'
import { LoaderSize, StyledLoader } from './styled'
import { Box } from 'components/Layout/Box'
import { Position } from 'csstype'

interface Props {
  size?: LoaderSize
  threshold?: number
  children?: React.ReactElement
  style?: React.CSSProperties
  position?: Position<string>
}

const Loading = ({ threshold = 30, size = LoaderSize.SMALL, children, style, position = 'absolute' }: Props) => {
  const [shouldDisplay, setShouldDisplay] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setShouldDisplay(true), threshold)
    return () => clearTimeout(timeout)
  }, [threshold])

  if (shouldDisplay) {
    return (
      <Box alignItems="center" justify="center" position={position} style={{ height: '100%', top: 0, width: '100%' }} direction={'column'}>
        <StyledLoader size={size} style={style}>
          <img src={require('assets/logo.png')} alt={'logo'} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
        </StyledLoader>
      </Box>
    )
  }

  return children || null
}

export default Loading
