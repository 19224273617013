import defaultConfig from './dev/conf'

declare global {
  interface Window {
    Conf: AWSConfig
  }
}

interface AWSConfig {
  USER_POOL_ID: string
  IDENTITY_POOL_ID: string
  USER_POOL_CLIENT_ID: string
  AWS_REGION: string
  MATCH_IMAGES_BUCKET_NAME: string
  STORY_ASSETS_BUCKET_NAME: string
  API_BASE_URL: string
  STORY_BASE_URL: string
  SENTRY_RELEASE: string
  SENTRY_ENVIRONMENT: string
  IMPORT_ASSETS_FUNCTION_NAME: string
  CLOUD_FRONT_DISTRIBUTION_ID: string
  GETTY_REPORT_FUNCTION_NAME: string
}

interface Config {
  cognito: AWSConfig
  topics: {
    matchImages: string
    matches: string
  }
  api: {
    baseUrl: string
    imagesBaseUrl: string
    apiBaseUrl: string
    imagesUrl: string
    teamLogoUrl: string
    subjectLogoUrl: string
    subjectPlaceholder: string
    teamPlaceholder: string
  }
  app: {
    pageTransitions: boolean
    debug: boolean
  }
  sentry: {
    SENTRY_DSN: string
    SENTRY_RELEASE: string
    SENTRY_ENVIRONMENT: string
  }
}

const awsConfig: AWSConfig = (window && window.Conf) || defaultConfig

const apiBaseUrl = awsConfig.API_BASE_URL
const storyBaseUrl = awsConfig.STORY_BASE_URL

const sentryRelease = awsConfig.SENTRY_RELEASE
const sentryEnvironment = awsConfig.SENTRY_ENVIRONMENT

const Config: Config = {
  cognito: awsConfig,
  topics: {
    matchImages: 'match-images',
    matches: 'matches',
  },
  api: {
    baseUrl: `${apiBaseUrl}/admin/api/v1/`,
    imagesUrl: `${storyBaseUrl}/match-images/`,
    subjectLogoUrl: `${apiBaseUrl}/stories/subject-logo/`,
    subjectPlaceholder: `${apiBaseUrl}/stories/subject-placeholder/`,
    teamLogoUrl: `${apiBaseUrl}/stories/team-logo/`,
    teamPlaceholder: `${storyBaseUrl}/team-placeholder/`,
    imagesBaseUrl: storyBaseUrl,
    apiBaseUrl: apiBaseUrl,
  },
  app: {
    pageTransitions: false,
    debug: false,
  },
  sentry: {
    SENTRY_DSN: 'https://f92ad556d07b4d95830d28edbafc30b3@o326126.ingest.sentry.io/5339861',
    SENTRY_RELEASE: sentryRelease,
    SENTRY_ENVIRONMENT: sentryEnvironment,
  },
}

export default Config
