import {
  AddNewPageParams,
  AddNewPageResponse,
  AddSimpleStoryBody,
  AddSimpleStoryPageBody,
  AddSimpleStoryPageResponse,
  AddSimpleStoryResponse,
  DeletePageParams,
  DeleteSimpleStoryPageParams,
  DeleteSimpleStoryPageResponse,
  DeleteSimpleStoryParams,
  DeleteSimpleStoryResponse,
  GenerateNewTitleParams,
  GenerateNewTitleResponse,
  GetAllImagesParams,
  GetAllSubjectsResponse,
  GetMatchesParams,
  GetMatchesResponse,
  GetMatchImagesParams,
  GetMatchImagesResponse,
  GetMatchParams,
  GetMomentParams,
  GetMomentResponse,
  GetPreselectedImagesParams,
  GetSimpleStoriesParams,
  GetSimpleStoriesResponse,
  GetSimpleStoryPageParams,
  GetSimpleStoryPageResponse,
  GetSimpleStoryParams,
  GetSimpleStoryResponse,
  ImageResetSearchTermParams,
  ImageSearchInfoParams,
  ImageSearchInfoResponse,
  ImageSearchResponse,
  ImageSearchTermResponse,
  ImageSetSearchTermParams,
  MatchDetailResponse,
  PointOfInterest,
  PostAutoFitMatchParams,
  RequestMatchImagesParams,
  RequestMatchImagesResponse,
  SavePageParams,
  SearchMatchImagesParams,
  SendNotificationsParams,
  SetPageStatusParams,
  SetSimpleStoryPageStatusParams,
  SetSimpleStoryPageStatusResponse,
  SetSimpleStoryStatusParams,
  SetSimpleStoryStatusResponse,
  SetTrendingParams,
  UploadPhotoParams,
  UploadPhotoResponse,
} from 'api/types'
import { Auth } from 'aws-amplify'
import Axios from 'axios'
import config from 'config'
import { handleError } from 'api/errors'
import fileDownload from 'js-file-download'

const baseURL: string = config.api.baseUrl

const getIdToken = () => Auth.currentSession().then(session => session.getIdToken().getJwtToken())

const getHeaders = async () => {
  return { authorization: await getIdToken() }
}

const client = Axios.create({ baseURL })

export const getMatches = async ({ date }: GetMatchesParams): Promise<GetMatchesResponse> =>
  client
    .get('matches', {
      params: {
        date,
      },
      headers: await getHeaders(),
    })
    .then(res => res.data)
    .catch(handleError)

export const getMatch = async ({ matchId }: GetMatchParams): Promise<MatchDetailResponse> =>
  await client
    .get(`matches/${encodeURIComponent(matchId)}`, { headers: await getHeaders() })
    .then(r => r.data)
    .catch(handleError)

export const requestMatchImages = async ({ matchId, query }: RequestMatchImagesParams): Promise<RequestMatchImagesResponse> =>
  client
    .post(`match-images/${encodeURIComponent(matchId)}/request/${encodeURIComponent(query)}`, undefined, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const searchMatchImages = async ({ matchId, query }: SearchMatchImagesParams): Promise<ImageSearchResponse> =>
  client
    .get(`match-images/${encodeURIComponent(matchId)}/search/${encodeURIComponent(query)}`, { headers: await getHeaders() })
    .then(r => r.data)
    .catch(handleError)

export const autoFitMatch = async ({ matchId }: PostAutoFitMatchParams): Promise<void> => {
  client.post(`stories/auto-fit/${encodeURIComponent(matchId)}`, undefined, { headers: await getHeaders() }).catch(handleError)
}

export const getMoment = async ({ pageId }: GetMomentParams): Promise<GetMomentResponse> => {
  const data =
    pageId &&
    pageId !== 'undefined' &&
    (await client.get(`stories/pages/${encodeURIComponent(pageId)}`, { headers: await getHeaders() }).catch(handleError))

  return data && data.data
}

export const setPageStatus = async ({ pageId, status }: SetPageStatusParams): Promise<undefined> =>
  client
    .post(
      `stories/pages/${encodeURIComponent(pageId)}/set-status`,
      {
        status,
      },
      { headers: await getHeaders() },
    )
    .then(res => res.data)
    .catch(handleError)

export const setTrending = async ({ storyId, isTrending }: SetTrendingParams): Promise<void> =>
  client
    .post(
      `stories/${encodeURIComponent(storyId)}/set-trending`,
      {
        isTrending,
      },
      { headers: await getHeaders() },
    )
    .then(res => res.data)
    .catch(handleError)

export const uploadPhoto = async ({ matchId, data }: UploadPhotoParams): Promise<UploadPhotoResponse> =>
  client
    .post(`matches/${encodeURIComponent(matchId)}/upload-photo`, data, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const addNewPage = async ({ storyId, data }: AddNewPageParams): Promise<AddNewPageResponse> =>
  client
    .post(`stories/${encodeURIComponent(storyId)}/pages`, data, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const savePage = async ({ pageId, data }: SavePageParams): Promise<void> =>
  client
    .put(
      `stories/pages/${encodeURIComponent(pageId)}`,
      {
        ...data,
      },
      { headers: await getHeaders() },
    )
    .then(res => res.data)
    .catch(handleError)

export const deletePage = async ({ pageId }: DeletePageParams): Promise<void> =>
  client
    .delete(`stories/pages/${encodeURIComponent(pageId)}`, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const getMatchImages = async ({ matchId, pageId }: GetMatchImagesParams): Promise<GetMatchImagesResponse> =>
  client
    .get(`match-images/${encodeURIComponent(matchId)}`, {
      params: {
        ...(pageId && { pageId }),
      },
      headers: await getHeaders(),
    })
    .then(res => res.data)
    .catch(handleError)

export const generateNewTitle = async ({ pageId }: GenerateNewTitleParams): Promise<GenerateNewTitleResponse> =>
  client
    .get(`stories/pages/${encodeURIComponent(pageId)}/generate-title`, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

type ImageUrlProps = {
  matchId: string
  imageId: string
  height?: string
  width?: string
  pointOfInterest: PointOfInterest
  ratio?: Ratios
}

export const getTeamLogo = (teamId: string): string => `${config.api.teamLogoUrl}${teamId}?negatePlaceholder=true`

export const getSubjectLogo = (storyId: string): string =>
  `${config.api.subjectLogoUrl}${encodeURIComponent(storyId)}?negatePlaceholder=true`

export enum Ratios {
  PORTRAIT = 'portrait',
  SQUARE = 'square',
  SHARE = 'share',
}

const ratios = {
  [Ratios.PORTRAIT]: 0.5625,
  [Ratios.SQUARE]: 1,
  [Ratios.SHARE]: 4 / 3,
}

export const getImageUrlPreview = ({ matchId, imageId, pointOfInterest, ratio }: ImageUrlProps): string => {
  if (!ratio) return ''

  const desiredAspectRatio = ratios[ratio]

  return `${config.api.imagesUrl}${encodeURIComponent(matchId)}?imageId=${encodeURIComponent(imageId)}&width=600&poiX=${encodeURIComponent(
    pointOfInterest.left,
  )}&poiY=${encodeURIComponent(pointOfInterest.top)}&poiW=${encodeURIComponent(pointOfInterest.width)}&poiH=${encodeURIComponent(
    pointOfInterest.height,
  )}&desiredAspectRatio=${encodeURIComponent(desiredAspectRatio)}`
}

export const getImageUrlPreviewWithAspectRatio = ({ matchId, imageId, height = '600' }: Omit<ImageUrlProps, 'pointOfInterest'>): string =>
  `${config.api.imagesUrl}${encodeURIComponent(matchId)}?imageId=${encodeURIComponent(imageId)}&width=${encodeURIComponent(height)}`

export const getImageUrlGallery = ({ matchId, imageId }: Omit<ImageUrlProps, 'pointOfInterest'>): string =>
  `${config.api.imagesUrl}${encodeURIComponent(matchId)}?imageId=${encodeURIComponent(imageId)}&width=300`

export const getSubjectPlaceholder = (storyId: string): string =>
  `${config.api.subjectPlaceholder}${encodeURIComponent(storyId)}?negatePlaceholder=true`

export const getStoryImageUrl = (storyId: string, pageId: string, timestamp: number) =>
  `${config.api.imagesBaseUrl}/story-image/${encodeURIComponent(storyId)}?pageId=${encodeURIComponent(
    pageId,
  )}&timestamp=${encodeURIComponent(timestamp)}`
export const getPostImageUrl = (storyId: string, pageId: string, timestamp: number) =>
  `${config.api.imagesBaseUrl}/og-image/${encodeURIComponent(storyId)}?pageId=${encodeURIComponent(pageId)}&timestamp=${encodeURIComponent(
    timestamp,
  )}`
export const getStoryAssetImage = (s3Key: string) => `${config.api.imagesBaseUrl}/${s3Key}`

export const getStoryStatsImageUrl = (storyId: string, pageId: string, timestamp: number) =>
  `${config.api.apiBaseUrl}/stories/story-image-stats/${encodeURIComponent(storyId)}?pageId=${encodeURIComponent(
    pageId,
  )}&timestamp=${encodeURIComponent(timestamp)}`
export const getPostStatsImageUrl = (storyId: string, pageId: string, timestamp: number) =>
  `${config.api.apiBaseUrl}/stories/og-image-stats/${encodeURIComponent(storyId)}?pageId=${encodeURIComponent(
    pageId,
  )}&timestamp=${encodeURIComponent(timestamp)}`

export const getAllImages = async ({ matchId, pageId, page }: GetAllImagesParams) =>
  client
    .get(`match-images/${encodeURIComponent(matchId)}`, {
      params: {
        pageId,
        page,
      },
      headers: await getHeaders(),
    })
    .then(res => res.data)
    .catch(handleError)

export const getPreselectedImages = async ({ matchId, pageId }: GetPreselectedImagesParams) =>
  client
    .get(`match-images/${encodeURIComponent(matchId)}/preselected`, {
      params: {
        pageId,
      },
      headers: await getHeaders(),
    })
    .then(res => res.data)
    .catch(handleError)

export const imageSearchSet = async ({ matchId, query }: ImageSetSearchTermParams): Promise<ImageSearchTermResponse> =>
  client
    .post(`match-images/${encodeURIComponent(matchId)}/search/${encodeURIComponent(query)}/set`, undefined, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const imageSearchReset = async ({ matchId }: ImageResetSearchTermParams): Promise<ImageSearchTermResponse> =>
  client
    .post(`match-images/${encodeURIComponent(matchId)}/search/reset`, undefined, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const imageSearchInfo = async ({ matchId }: ImageSearchInfoParams): Promise<ImageSearchInfoResponse> =>
  client
    .get(`match-images/${encodeURIComponent(matchId)}/search`, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const addSimpleStory = async (data: AddSimpleStoryBody): Promise<AddSimpleStoryResponse> =>
  client
    .post(`simple-stories/`, data, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const getSimpleStory = async ({ storyId }: GetSimpleStoryParams): Promise<GetSimpleStoryResponse> =>
  client
    .get(`simple-stories/${encodeURIComponent(storyId)}`, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const setSimpleStoryStatus = async ({ storyId, ...data }: SetSimpleStoryStatusParams): Promise<SetSimpleStoryStatusResponse> =>
  client
    .post(`simple-stories/${encodeURIComponent(storyId)}/set-status`, data, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const getSimpleStoryPage = async ({ storyId, pageId }: GetSimpleStoryPageParams): Promise<GetSimpleStoryPageResponse> =>
  client
    .get(`simple-stories/${encodeURIComponent(storyId)}/page/${encodeURIComponent(pageId)}`, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const addSimpleStoryPage = async ({ storyId, ...data }: AddSimpleStoryPageBody): Promise<AddSimpleStoryPageResponse> =>
  client
    .post(`simple-stories/${encodeURIComponent(storyId)}/page`, data, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const saveSimpleStoryPage = async (data: AddSimpleStoryPageBody): Promise<AddSimpleStoryPageResponse> =>
  client
    .put(`simple-stories/${encodeURIComponent(data.storyId)}/page/${encodeURIComponent(data.id)}`, data, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const setSimpleStoryPageStatus = async ({
  storyId,
  pageId,
  ...data
}: SetSimpleStoryPageStatusParams): Promise<SetSimpleStoryPageStatusResponse> =>
  client
    .post(`simple-stories/${encodeURIComponent(storyId)}/page/${encodeURIComponent(pageId)}/set-status`, data, {
      headers: await getHeaders(),
    })
    .then(res => res.data)
    .catch(handleError)

export const deleteSimpleStoryPage = async ({ storyId, pageId }: DeleteSimpleStoryPageParams): Promise<DeleteSimpleStoryPageResponse> =>
  client
    .delete(`simple-stories/${encodeURIComponent(storyId)}/page/${encodeURIComponent(pageId)}`, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const deleteSimpleStory = async ({ storyId }: DeleteSimpleStoryParams): Promise<DeleteSimpleStoryResponse> =>
  client
    .delete(`simple-stories/${encodeURIComponent(storyId)}`, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const getSimpleStories = async ({ date }: GetSimpleStoriesParams): Promise<GetSimpleStoriesResponse> =>
  client
    .get(`simple-stories/`, {
      params: {
        date,
      },
      headers: await getHeaders(),
    })
    .then(res => res.data)
    .catch(handleError)

export const sendNotifications = async ({ storyId, ...data }: SendNotificationsParams): Promise<void> =>
  client
    .post(`simple-stories/${encodeURIComponent(storyId)}/send-notifications`, data, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const getAllSubjects = async (): Promise<GetAllSubjectsResponse> =>
  client
    .get(`subjects`, { headers: await getHeaders() })
    .then(res => res.data)
    .catch(handleError)

export const downloadGettyReportCSV = async (year: string, month: string): Promise<void> =>
  client
    .get(`getty-report?year=${year}&month=${month}`, { headers: await getHeaders() })
    .then(res => fileDownload(res.data, `getty-report-${year}-${month}.csv`))
    .catch(handleError)
