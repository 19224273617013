import AWS from 'aws-sdk'
import Auth from '@aws-amplify/auth'

import config from 'config'

import { ulid } from 'ulid'
import moment from 'moment'
import { range, splitEvery } from 'ramda'
import { downloadGettyReportCSV } from 'api/requests/index'

const getOptions = async () => {
  const credentials = await Auth.currentCredentials()

  return {
    region: config.cognito.AWS_REGION,
    accessKeyId: credentials.accessKeyId,
    secretAccessKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
  }
}

const getCloudFront = async () => {
  const options = await getOptions()
  return new AWS.CloudFront(options)
}

const getLambda = async () => {
  const options = await getOptions()
  return new AWS.Lambda(options)
}

export const importAssets = async () => {
  const lambda = await getLambda()
  return await lambda
    .invoke({
      FunctionName: config.cognito.IMPORT_ASSETS_FUNCTION_NAME,
      InvocationType: 'RequestResponse',
      LogType: 'Tail',
      Payload: JSON.stringify({}),
    })
    .promise()
}

export const createInvalidation = async () => {
  const cloudFront = await getCloudFront()

  return await cloudFront
    .createInvalidation({
      DistributionId: config.cognito.CLOUD_FRONT_DISTRIBUTION_ID,
      InvalidationBatch: {
        CallerReference: ulid(),
        Paths: {
          Items: ['/player*', '/team*', '/subject*'],
          Quantity: 3,
        },
      },
    })
    .promise()
}

export const invalidationResult = async (id: string): Promise<AWS.CloudFront.GetInvalidationResult> => {
  const cloudFront = await getCloudFront()
  const invalidationResult = await cloudFront
    .getInvalidation({
      DistributionId: config.cognito.CLOUD_FRONT_DISTRIBUTION_ID,
      Id: id,
    })
    .promise()

  if (invalidationResult.Invalidation?.Status !== 'Completed') throw new Error('Invalidation status check timed out')

  return invalidationResult
}

export const generateGettyReport = async (date: string) => {
  const lambda = await getLambda()
  const year = moment(date).format('YYYY')
  const month = moment(date).format('MM')
  const start = moment.utc(`${year}-${month}-01`)
  const daysOfMonth = range(0, start.daysInMonth()).map(i => start.clone().add(i, 'days').format('YYYY-MM-DD'))

  await Promise.all(
    daysOfMonth.map(ymd =>
      lambda
        .invoke({
          FunctionName: config.cognito.GETTY_REPORT_FUNCTION_NAME,
          InvocationType: 'RequestResponse',
          Payload: JSON.stringify({ forceDate: ymd }),
        })
        .promise(),
    ),
  ).then(() => downloadGettyReportCSV(year, month))
}
