export enum Stage {
  ALPHA = 'alpha',
  BETA = 'beta',
  PROD = 'prod',
  LOCALHOST = 'localhost',
}

export interface IConf {
  STAGE: Stage
  USER_POOL_CLIENT_ID: string
  USER_POOL_ID: string
  IDENTITY_POOL_ID: string
  REGION: string
}

export enum ColorType {
  NORMAL = 'normal',
  PRIMARY = 'primary',
  SUCCESS = 'success',
  FAILURE = 'failure',
  WARNING = 'warning',
  INFO = 'info',
}

interface ISpacing {
  XXS: 2
  XS: 4
  S: 8
  M: 16
  L: 32
  XL: 64
  XXL: 128
}

export const Spacing: ISpacing = {
  XXS: 2,
  XS: 4,
  S: 8,
  M: 16,
  L: 32,
  XL: 64,
  XXL: 128,
}

export enum Breakpoints {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}
