import create from 'zustand'

interface SettingsStore {
  assetsLogs: string
  assetsLoading: boolean
  setAssetsLogs: (data: string) => void
  setAssetsLoading: (data: boolean) => void
}

export const useSettings = create<SettingsStore>(set => ({
  assetsLogs: '',
  assetsLoading: false,
  setAssetsLogs: data => set(() => ({ assetsLogs: data })),
  setAssetsLoading: data => set(() => ({ assetsLoading: data })),
}))
