import React from 'react'
import { Button, DatePicker, Space } from 'antd'
import { useGenerateGettyReport } from 'api/mutations/useGenerateGettyReport'
import moment, { Moment } from 'moment'

const GettyReportTab: React.FC = () => {
  const [date, setDate] = React.useState<null | string>(null)

  const { mutate: generateReport, isLoading } = useGenerateGettyReport()

  const disabledDate = (current: Moment) => {
    return current && current >= moment().startOf('month')
  }
  const handleDate = date => setDate(moment(date).format('YYYY-MM'))
  const handleGenerateReport = () => {
    if (date) generateReport({ date })
  }

  return (
    <React.Fragment>
      <Space size="large">
        <DatePicker onChange={handleDate} picker="month" disabledDate={disabledDate} allowClear={false} />
        <Button disabled={!date || isLoading} loading={isLoading} onClick={handleGenerateReport}>
          GENERATE GETTY REPORT
        </Button>
      </Space>
    </React.Fragment>
  )
}

export default GettyReportTab
