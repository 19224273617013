import styled from 'styled-components'
import { Box } from 'components/Layout/Box'

export const Layout = styled(Box)`
  flex-direction: column;
  flex: 1;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 0;
  width: 100%;
`
