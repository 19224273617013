import Auth from '@aws-amplify/auth'
import PubSub, { AWSIoTProvider } from '@aws-amplify/pubsub'
import AWS from 'aws-sdk'
import config from 'config'
import { useAuth } from 'hooks/useAuth'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { AuthState } from '../Auth/context'
import { AddSubscription, Subscriptions, UsePubSubProps } from './@typings'
import { PubsubContext } from './context'

const randomId = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

const PubSubProvider = ({ children }) => {
  const subscriptions = useRef<Subscriptions>({})
  const [initialized, setInitialized] = useState<boolean>(false)
  const [isConnecting, setIsConnecting] = useState<boolean>(false)
  const { data: user, state } = useAuth()

  const initializePubSub = () => {
    console.log('Initiating pubsub..')
    if (isConnecting || initialized || (!user && state !== AuthState.VERIFIED)) return
    setIsConnecting(true)

    console.log('Setting up pubsub..')
    void Auth.currentAuthenticatedUser().then(async (user: any) => {
      const credentials = await Auth.currentCredentials()

      const iot = new AWS.Iot({
        region: config.cognito.AWS_REGION,
        accessKeyId: credentials.accessKeyId,
        secretAccessKey: credentials.secretAccessKey,
        sessionToken: credentials.sessionToken,
      })

      await iot
        .attachPrincipalPolicy({
          policyName: 'PubSub',
          principal: credentials.identityId,
        })
        .promise()

      const iotEndpointAddress = await iot
        .describeEndpoint({ endpointType: 'iot:Data-ATS' })
        .promise()
        .then(r => r.endpointAddress)

      if (!iotEndpointAddress) return setInitialized(false)

      const provider = new AWSIoTProvider({
        aws_pubsub_endpoint: `wss://${iotEndpointAddress}/mqtt`,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        clientId: `${user.attributes.sub}-${randomId()}`,
        aws_pubsub_region: config.cognito.AWS_REGION,
      })

      void PubSub.addPluggable(provider).then(() => {
        console.log('Initialized')
        setInitialized(true)
      })
    })

    // Unsubscribe from all subscriptions
    return () => {
      const subs = Object.values(subscriptions.current)
      if (subs.length > 0 && initialized) {
        subs.forEach(sub => sub?.unsubscribe())
      }
    }
  }

  useEffect(initializePubSub, [initialized, user, state])

  const addSubscription: AddSubscription = useCallback(
    (topic, client) => {
      subscriptions.current[topic] = PubSub.subscribe([topic]).subscribe({
        start: () => console.log('Subscribing started'),
        complete: () => console.log('Subscription complete'),
        next: event => client?.onMessage(event),
        error: error => client?.onError(error),
      })
    },
    [user],
  )

  return <PubsubContext.Provider value={{ addSubscription, initialized }}>{children}</PubsubContext.Provider>
}

interface UsePubsubOptions<T> {
  onMessage?: (value: T) => void
  onError?: (error: any) => void
}

export const usePubSub = <T,>(topic: string, options: UsePubsubOptions<T> = {}): UsePubSubProps<T> => {
  const { addSubscription, initialized } = useContext(PubsubContext)

  const noHandlerFallback = () => {}

  const onMessage = options.onMessage || noHandlerFallback
  const onError = options.onError || noHandlerFallback

  const [isReady, setReady] = useState<boolean>(false)
  const [data, setData] = useState<any>(null)
  const [error, setError] = useState<any>(null)

  const handleMessage = useCallback(onMessage, [onMessage])
  const handleError = useCallback(onError, [onError])

  const isReadyToSubscribe = () => {
    if (isReady) return

    if (!initialized) {
      return setReady(false)
    }

    if (initialized) {
      return setReady(true)
    }
  }

  const addTopicSubscription = () => {
    if (!addSubscription) {
      return console.warn('No context handler')
    }

    if (!initialized) {
      return
    }

    const client = {
      onMessage: ({ value }) => {
        setData(value)
        handleMessage(value)
      },
      onError: err => {
        setError(err)
        handleError(err)
      },
    }

    if (isReady) {
      console.log('Add subscription for topic')
      addSubscription(topic, client)
    }
  }

  useEffect(isReadyToSubscribe, [initialized])
  useEffect(addTopicSubscription, [isReady])

  return [data, { error }]
}

export default PubSubProvider
