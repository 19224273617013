import Auth from '@aws-amplify/auth'
import { CognitoUser } from 'amazon-cognito-identity-js'
import React, { useCallback, useEffect, useState } from 'react'
import { AuthContext, AuthState, CognitoData, ContextProps } from './context'
import { toCognitoData } from './factory'

interface Props {
  children: JSX.Element
}

const AuthProvider = ({ children }: Props) => {
  const [state, setState] = useState<AuthState>(AuthState.PENDING)
  const [data, setData] = useState<CognitoData | null>(null)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const token = localStorage.getItem('jwtToken')
    if (token) setState(AuthState.VERIFIED)
    if (!token) return setState(AuthState.NOT_VERIFIED)

    Auth.currentSession()
      .then(data => {
        const payload = data.getIdToken().payload
        setData(toCognitoData(payload))
        setState(AuthState.VERIFIED)
      })
      .catch(e => {
        setError(e?.message)
        setState(AuthState.NOT_VERIFIED)
      })
  }, [])

  const handleSignIn = useCallback<any>(async ({ username, password }) => {
    return Auth.signIn({ username, password })
      .then(async (data: CognitoUser | null) => {
        const jwtToken = data?.getSignInUserSession()?.getAccessToken()?.getJwtToken()
        if (jwtToken) {
          const payload = data?.getSignInUserSession()?.getIdToken()?.payload

          if (payload) {
            setData(toCognitoData(payload))
            await localStorage.setItem('jwtToken', jwtToken)
            setState(AuthState.VERIFIED)
            return jwtToken
          }

          return
        }

        return setState(AuthState.NOT_VERIFIED)
      })
      .catch(({ message }) => {
        setError(message)
        setState(AuthState.NOT_VERIFIED)
      })
  }, [])

  const loginSuccess = useCallback<any>(async token => {
    await localStorage.setItem('token', token)
    setState(AuthState.VERIFIED)
  }, [])

  const logout = useCallback<any>(async () => {
    await localStorage.removeItem('jwtToken')

    setData(null)
    setState(AuthState.NOT_VERIFIED)
  }, [])

  const contextProps: ContextProps = {
    state,
    data,
    loginSuccess,
    logout,
    handleSignIn,
    error,
  }

  return <AuthContext.Provider value={contextProps}>{children}</AuthContext.Provider>
}

export default AuthProvider
