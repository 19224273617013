import styled from 'styled-components'

export const LogoImage = styled.img<{ w: number; h: number; clickable: boolean }>`
  width: ${({ w }) => `${w}px`};
  height: ${({ h }) => `${h}px`};
  transition: all 100ms ease-in;
  object-fit: contain;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover {
    transform: ${({ clickable }) => (clickable ? `scale(1.2)` : `scale(1)`)};
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  }
`
