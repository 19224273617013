import { Tabs } from 'antd'
import Glue from 'aws-sdk/clients/glue'
import Main from 'components/Layout/Main'
import NavBreadcrumbs from 'components/NavBreadcrumbs'
import config from 'config'
import { Layout } from 'pages/Matches/styled'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import AssetsTab from 'pages/Settings/_components/AssetImport'
import GettyReportTab from 'pages/Settings/_components/GettyReport'

enum TabKeys {
  ASSETS = 'ASSETS',
  GETTY_REPORT = 'GETTY_REPORT',
  BIG_QUERY = 'BIG_QUERY',
}

const Settings = () => {
  // WIP: Feature - big query
  const big = async () => {
    console.log(Glue)
    const glue = new Glue({
      region: config.cognito.AWS_REGION,
      // accessKeyId: TODO: prod keys
      // secretAccessKey:
    })

    await glue
      .startJobRun({
        JobName: 'Big Query Export',
      })
      .promise()
      .then(r => {
        console.log(r)
      })

    //TODO: pooling stejne jako v assets
    await glue
      .getJobRuns({
        JobName: 'Big Query Export',
      })
      .promise()
      .then(r => {
        // console.log(r)
        console.log(r.JobRuns?.[0])
        console.log(r.NextToken)
      })
  }

  return (
    <Main
      header={
        <>
          <NavBreadcrumbs label={'Settings'} />
        </>
      }
    >
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <Layout>
        <Tabs defaultActiveKey={TabKeys.ASSETS}>
          <Tabs.TabPane tab="Assets" key={TabKeys.ASSETS}>
            <AssetsTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Getty report" key={TabKeys.GETTY_REPORT}>
            <GettyReportTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab="BigQuery" key={TabKeys.BIG_QUERY}>
            Coming soon
            {/*<Button onClick={() => big()}>BIG</Button>*/}
          </Tabs.TabPane>
        </Tabs>
      </Layout>
    </Main>
  )
}

export default Settings
