import { Wrapper } from './styled'
import React, { Suspense } from 'react'
import Menu from 'components/Menu'
import Loading from 'components/Loading'

interface MainProps {
  header?: React.ReactNode
}

const Main: React.FC<MainProps> = ({ header, children }) => {
  return (
    <Suspense fallback={<Loading />}>
      <Menu>{header}</Menu>
      <Wrapper>{children}</Wrapper>
    </Suspense>
  )
}

export default Main
