import 'antd/dist/antd.css'

import * as React from 'react'

import { Protected, Public } from 'routes'

import { AuthState } from 'hooks/providers/Auth/context'
import PubSubProvider from 'hooks/providers/PubSub'
import { QueryClientProvider } from 'react-query'
import QueryErrorBoundary from 'components/ErrorBoundary'
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from 'api'
import { useAuth } from 'hooks/useAuth'

const App = () => {
  const { state } = useAuth()

  return (
    <React.Suspense fallback={null}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <QueryErrorBoundary>
          {state === AuthState.NOT_VERIFIED && <Public />}
          {state === AuthState.VERIFIED && (
            <PubSubProvider>
              <Protected />
            </PubSubProvider>
          )}
        </QueryErrorBoundary>
      </QueryClientProvider>
    </React.Suspense>
  )
}

export default App
