import { CognitoData } from './context'

export const toCognitoData = (payload: Record<string, any>): CognitoData => {
  const { sub, aud, exp, iat, iss, auth_time } = payload
  return {
    restaurantId: payload['custom:restaurantId'],
    roles: payload['cognito:groups'] || [],
    username: payload['cognito:username'],
    email: payload['email'],
    authTime: auth_time,
    id: sub,
    aud,
    exp,
    iat,
    iss,
  }
}
