import styled from 'styled-components'

export const UserButton = styled.a`
  background-color: rgba(0, 0, 0, 0.85);
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`
