import styled from 'styled-components'

export const BreadcrumbSeparator = styled.span`
  color: color: 'rgba(0, 0, 0, 0.45)';
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
`

export const BreadcrumbItem = styled.span<{ isLast: boolean }>`
  color: ${({ isLast }) => (isLast ? 'rgba(0, 0, 0, 0.85)' : 'rgba(0, 0, 0, 0.45)')};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
  margin: 12px 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover {
    cursor: ${({ isLast }) => (!isLast ? 'pointer' : 'text')};
    text-decoration: ${({ isLast }) => (!isLast ? 'underline' : 'none')};
  }
`

export const BreadcrumbItemLast = styled.span<{ color: string; disable: boolean }>`
  color: ${({ color }) => `${color}`};
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
  margin: 12px 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover {
    cursor: ${({ disable }) => (!disable ? 'pointer' : 'text')};
    text-decoration: ${({ disable }) => (!disable ? 'underline' : 'none')};
  }
`

export const MatchDate = styled.span`
  font-family: Rajdhani, Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.45);
  margin-left: 24px;
`
