import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  body {
    font-family: 'Rajdhani', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: ${(props: any) => props.theme.colors.background};
  
  }

  p {
    margin: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }
  
  // @override Scrollbar
  ::-webkit-scrollbar-track {
    margin-right: 48px;
    margin-left: 24px;
    border-radius: 3px !important;
    background: #D7D8D9 !important;
  }
  
  ::-webkit-scrollbar {
    border-radius: 3px !important;
    height: 6px !important;
    width: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #2F3744 !important; 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #aeaeae !important;
  }
  
  // @override AntTable
  .ant-table-thead .ant-table-cell {
    background-color: transparent;
    padding-top: 48px;
  }
  
  .ant-table-tbody > tr.ant-table-row-level-0 > td {
    padding: 0;
  }
  
  .ant-table-tbody > tr.ant-table-row-level-0:hover > td {
    background-color: #F0F0F0;
    cursor: pointer;
  }
  
  .ant-switch-checked {
    background-color: #73D13D;
  }
  
  .ant-tabs-nav-wrap {
    height: 55px;
  }
  
  .ant-upload .ant-upload-btn {
    padding: 0 !important;
    height: 40px !important;
  }
  
  .cropper-crop {
    background-color: white;
  }
`
