enum Routes {
  ROOT = '/',

  // auth
  SIGN_IN = '/sign-in',

  // admin
  MATCHES = '/matches/:date?',

  MATCH = '/match/:matchId',
  MATCH_STORY = '/match/:matchId/story/:storyId',
  MATCH_STORY_MOMENT_EDIT = '/match/:matchId/story/:storyId/type/:type/page/:pageId',
  MATCH_STORY_MOMENT_ADD = '/match/:matchId/story/:storyId/type/:type/:timestamp',
  CUSTOM_EVENT = '/match/:matchId/story/:storyId/:timestamp',
  SIMPLE_STORY = '/story/:storyId',
  SIMPLE_STORY_PAGE = '/story/:storyId/type/:type/page/:pageId',
  SETTINGS = '/settings',
}

export default Routes
