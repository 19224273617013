import React, { MouseEventHandler } from 'react'
import { LogoImage } from './styled'

export enum LogoType {
  SMALL = 'SMALL',
  BIG = 'BIG',
}

export const dimensions = {
  [LogoType.SMALL]: {
    w: 28,
    h: 28,
  },
  [LogoType.BIG]: {
    w: 100,
    h: 100,
  },
}

export interface LogoProps {
  type: LogoType
  onClick?: () => void
}

const Logo: React.FC<LogoProps> = ({ type, onClick }): JSX.Element => {
  const { w, h } = dimensions[type]

  const props = onClick && {
    onClick,
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        zIndex: 3,
      }}
      {...props}
    >
      <LogoImage src={require('./logo.png')} alt={'logo'} {...{ w, h, clickable: !!onClick }} />
    </div>
  )
}

export default Logo
