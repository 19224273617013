import React from 'react'
import { Box } from 'components/Layout/Box'
import { BreadcrumbItem, BreadcrumbSeparator, MatchDate } from './styled'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'

interface Props {
  links?: { name: string; url: string }[]
  label?: string
  date?: string
}

const NavBreadcrumbs: React.FC<Props> = ({ links, label, date }) => {
  const isLast = (index: number) => !!links && index + 1 === links.length && !label

  return (
    <Box alignItems="center">
      <Breadcrumb separator={<BreadcrumbSeparator>/</BreadcrumbSeparator>}>
        {links &&
          links.map((link, i) => (
            <Breadcrumb.Item key={i}>
              <Link to={link.url}>
                <BreadcrumbItem isLast={isLast(i)}>{link.name}</BreadcrumbItem>
              </Link>
            </Breadcrumb.Item>
          ))}
        {label && (
          <Breadcrumb.Item>
            <BreadcrumbItem isLast>{label}</BreadcrumbItem>
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
      <MatchDate>{date}</MatchDate>
    </Box>
  )
}

export default NavBreadcrumbs
