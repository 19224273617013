import { createContext } from 'react'

export interface CognitoData {
  roles: string[]
  aud: string
  authTime: number
  username: string
  email: string
  id: string
  restaurantId: string
  exp: number
  iat: number
  iss: string
}

export enum AuthState {
  PENDING = 'pending',
  VERIFIED = 'verified',
  NOT_VERIFIED = 'not_verified',
}

export enum ListenerEvent {
  SIGN_IN = 'signIn',
  SIGN_UP = 'signUp',
  SIGN_OUT = 'signOut',
  SIGN_IN_FAILURE = 'signIn_failure',
  CONFIGURED = 'configured',
}

export interface ContextProps {
  state?: AuthState | null
  data?: CognitoData | null
  loginSuccess?: (values: any) => void
  logout?: () => void
  error?: any
  handleSignIn?: ({ username, password }) => Promise<string | null>
}

const initialContextProps: ContextProps = {
  state: null,
  data: null,
}

export const AuthContext = createContext<ContextProps>(initialContextProps)
