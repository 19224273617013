import React from 'react'
import { Button, Modal } from 'antd'
import { Logs } from './styled'
import { useSettings } from 'stores/useSettings'
import { useImportAssets } from 'api/mutations/useImportAssets'
import { useCreateInvalidation } from 'api/mutations/useCreateInvalidation'
import { useInvalidationStatus } from 'api/mutations/useInvalidationStatus'
import { Box } from 'atomic-layout'
import { Spacing } from 'constants/@typings'
import Highlight from 'react-highlighter'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal

const AssetsTab: React.FC = () => {
  const { assetsLogs, assetsLoading, setAssetsLoading, setAssetsLogs } = useSettings()

  const { mutate: getInvalidationStatus } = useInvalidationStatus({
    retry: 5,
    retryDelay: 10000,
    onSuccess: () => setAssetsLoading(false),
  })

  const { mutate: createInvalidation } = useCreateInvalidation({
    onSuccess: data => {
      if (!data.Invalidation?.Id) {
        throw new Error('Missing invalidation id, cannot check invalidation status')
      }
      getInvalidationStatus({
        id: data.Invalidation.Id,
      })
    },
  })

  const { mutate: importAssets } = useImportAssets({
    onMutate: () => setAssetsLoading(true),
    onSuccess: data => {
      setAssetsLogs(atob(data.LogResult ?? ''))
      createInvalidation()
    },
  })

  const handleImport = () =>
    confirm({
      title: 'Confirm asset import',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure to import assets?',
      onOk() {
        importAssets()
      },
    })

  return (
    <React.Fragment>
      <Box fullWidth marginBottom={Spacing.L} justify={'center'}>
        <Button disabled={assetsLoading} loading={assetsLoading} onClick={handleImport}>
          IMPORT ASSETS
        </Button>
      </Box>
      <Logs>
        <Highlight search="error">{assetsLogs}</Highlight>
      </Logs>
    </React.Fragment>
  )
}

export default AssetsTab
