import styled from 'styled-components'

export const Logs = styled.div`
  font-family: monospace;
  background-color: #b7b7b7;
  padding: 2ch;
  box-shadow: inset 0 0 11px 0 #9b9b9b;
  border-radius: 3px;
  min-height: 300px;
  white-space: pre;
  overflow: auto;
`
