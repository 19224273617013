const Conf = {
  AWS_NODEJS_CONNECTION_REUSE_ENABLED: '1',
  AWS_SDK_LOAD_CONFIG: '1',
  AWS_REGION: 'eu-west-1',
  USER_POOL_CLIENT_ID: '5rkc8ocpauvdqrstcqjtnucqaa',
  USER_POOL_ID: 'eu-west-1_fUkAwfvld',
  IDENTITY_POOL_ID: 'eu-west-1:201bf981-5057-47fd-8b5b-cd2a649516c5',
  API_BASE_URL: 'https://api.sportlito.alpha.qestapp.net',
  STORY_BASE_URL: 'https://story.sportlito.alpha.qestapp.net',
  MATCH_IMAGES_BUCKET_NAME: 'alpha-sportlito-match-images',
  STORY_ASSETS_BUCKET_NAME: 'alpha-sportlito-story-assets',
  SENTRY_RELEASE: 'development',
  SENTRY_ENVIRONMENT: 'ALPHA_ADMIN',
  IMPORT_ASSETS_FUNCTION_NAME: 'SportlitoBackend-AssetsImportImporterDC40A0DA-U8IQHXTRU5EO',
  CLOUD_FRONT_DISTRIBUTION_ID: 'E31U7YS9R7HAP2',
  GETTY_REPORT_FUNCTION_NAME: 'SportlitoBackend-GettyImageSourceImageReportingE16-1CZ9NIQK4SOSH',
}

export default Conf
