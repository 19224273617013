import { Box } from 'components/Layout/Box'
import Logo, { LogoType } from 'components/Logo'
import { AuthState } from 'hooks/providers/Auth/context'
import { useAuth } from 'hooks/useAuth'
import moment from 'moment'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Routes from 'routes/routes'
import { substitute } from 'utils/substitute'
import UserMenu from './_components/UserMenu'

const Menu: React.FC = ({ children }) => {
  const { logout, state } = useAuth()
  const { push } = useHistory()

  return (
    <Box
      fullWidth
      height={64}
      backgroundColor={'#FFFFFF'}
      justify={'space-between'}
      alignItems="center"
      style={{
        ...(state === AuthState.VERIFIED && { boxShadow: 'inset 0px -1px 0px #F0F0F0' }),
        zIndex: 100,
      }}
      paddingLeft={26}
      paddingRight={24}
    >
      {state === AuthState.VERIFIED && (
        <>
          <Logo
            type={LogoType.SMALL}
            onClick={() => {
              push(substitute(Routes.MATCHES, { date: moment().valueOf() }))
            }}
          />
          <Box flex={10} justify="space-between" alignItems="center" paddingLeft={24} paddingRight={24}>
            {children}
          </Box>
          {logout && (
            <UserMenu
              menuItems={[
                { title: 'Log Out', onSelect: logout },
                { title: 'Settings', onSelect: () => push(Routes.SETTINGS) },
              ]}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default Menu
